// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"; // Import getDatabase
import { getAnalytics, logEvent } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1Vo4zaKFfWiZ63XdrKBAoEvXfP3bSkWQ",
  authDomain: "portfolio-site-ee69e.firebaseapp.com",
  databaseURL: "https://portfolio-site-ee69e-default-rtdb.firebaseio.com",
  projectId: "portfolio-site-ee69e",
  storageBucket: "portfolio-site-ee69e.appspot.com",
  messagingSenderId: "349605059756",
  appId: "1:349605059756:web:32ecac952d1e4275b5d309",
  measurementId: "G-D7HS3L7RYF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get the Firebase database instance
const database = getDatabase(app);

// Function to log events
const logAnalyticsEvent = (eventName, eventParams) => {
  logEvent(analytics, eventName, eventParams);
};

export { database, logAnalyticsEvent };
