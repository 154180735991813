import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import foregroundImage from './assets/background-assets/foreground.webp';
import loganImage from './assets/background-assets/logan.webp';
import aboutImage from './assets/background-assets/off-white.png';
import backgroundImage from './assets/background-assets/background.webp'

const preloadImage = (href) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.href = href;
  link.as = 'image';
  document.head.appendChild(link);
};

preloadImage(foregroundImage);
preloadImage(backgroundImage);
preloadImage(loganImage);
preloadImage(aboutImage);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);


