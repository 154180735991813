import React, {useEffect, useState} from "react";
import { Helmet } from 'react-helmet';
import HeaderParallaxBackground from "../components/HeaderParallaxBackground";
import {Parallax} from 'react-parallax';
import about_me_background from '../assets/background-assets/off-white.png'
import TypeWriterWrapper from "../components/TypeWritterWrapper";
import '../styles/Home.scss'



const Home = ({aboutRef, shouldScrollToAbout}) => {

    // parallax stuff ---
    const[offsetY, setOffsetY] = useState(0); // used to store data on how far user scrolled
    const handleScroll = () => {
        requestAnimationFrame(() => {
            // the handleScroll invokes the setOffsetY useState updater and updates the offsetY variable with the current scroll amount
            setOffsetY(window.pageYOffset);
        });
    }
    useEffect(() => { // runs every render of page
        window.addEventListener("scroll", handleScroll); // creates event listener every render so that when user scrolls, invoke the handleScroll
        return () => window.removeEventListener("scroll", handleScroll); // when component (home) is unmounted (re-routed, stop being used), then remove the event listener for the user to scroll
    },[])
    // -------------------------------------------------------------------------------
    useEffect(() => {
        if (shouldScrollToAbout && aboutRef.current) {
          aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [shouldScrollToAbout, aboutRef]);


    return ( 
        
        <div className="parallax_container">
            <Helmet>
                <title>Logan Falkenberg - Home</title>
                <meta name="description" content="Logan Falkenberg's personal portfolio showcasing skills and projects in computer science and software development." />
                <meta name="keywords" content="Logan Falkenberg, Portfolio, Computer Science, Software Development, React" />
            </Helmet>
            <TypeWriterWrapper/>
            <HeaderParallaxBackground offsetY={offsetY}/> 
            {/* Wrapper div with top margin (1000px point is where img starts) */}
            <div className="lower_parallax" style={{ marginTop: '%' }}>
                <Parallax strength={200} bgImage={about_me_background}>
                <div className="about-me-section" ref={aboutRef} id="about">
                    <h2>About Me</h2>
                    <p>
                        My name is Logan Falkenberg, a senior at Texas State University, majoring in Computer Science with a deep interest in full-stack development. I am driven by the challenges and rewards of creating efficient, convenient software solutions and applications that marry functionality with user-friendly interfaces.
                    </p>
                    <p>
                        With each project, my goal is to deliver software that is intuitive for users, characterized by elegant and maintainable code. While I enjoy web development, my true passion lies in full-stack development, where I can engage with all aspects of software creation.
                    </p>
                    <p>
                        As a curious innovator and problem-solver, I'm constantly expanding my skill set. I thrive in collaborative environments and work seamlessly within teams to drive project success. My experience as a Lead Instructor at Code Ninjas has not only honed my technical abilities but has also refined my leadership and educational skills.
                    </p>
                    <p>
                        As I progress in my academic and professional journey, I seek to leverage my growing experience in meaningful projects, pushing the envelope of what's possible in programming. My focus remains on developing software solutions that are both efficient and convenient, making a real impact in the field.
                    </p>
                </div>

                </Parallax>
            </div>
        </div>
    );
}
 
export default Home;