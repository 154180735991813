import React from 'react';
import ProjectCard from '../scripts/ProjectCard.jsx';
import '../styles/Projects.css';

const Projects = () => {
    return (
        <div className='projects-container'>
            <h2>Projects</h2>
            <ProjectCard 
                thumbnail="/assets/videos/password-manager.mp4"
                title="Falkenberg's Password Manager"
                description="Falkenberg's Password Manager is a secure, lightweight, and entirely local password management solution. Designed with offline security in mind, it ensures your passwords remain protected and accessible even without internet connectivity. Featuring robust XOR-based encryption, SHA256 hashing, customizable themes, and a user-friendly interface, it’s perfect for users who prioritize control, privacy, and strong data protection."
                url="https://github.com/TheTallProgrammer/Password-Manager"
            />
            <ProjectCard 
                thumbnail="/assets/videos/discord-bot.mp4" 
                title="GPT Oracle: Discord Bot"
                description="The GPT-Oracle Discord Bot comes in two versions: Lite and Ultra, catering to different user needs. The Lite version is ideal for individual use or small servers, featuring straightforward AI chatbot functionalities with local API key storage. The Ultra version, suited for larger servers and multiple users, offers advanced features like remote SQL database for API key management and a broader command set, including `!ask` for AI conversations and `!draw` for AI-generated art."
                url="https://github.com/TheTallProgrammer/GPT-Oracle-Discord-Bot"
            />
            <ProjectCard 
                thumbnail="/assets/videos/stock-contender.mp4"
                title="Stock Contender"
                description="Stock Contender is designed for identifying promising stocks, powered by advanced algorithms and AI insights using OpenAI's GPT model. It analyzes a wide range of data, including trends and financial information, to suggest stocks for further exploration. While it offers a user-friendly interface and robust data analysis, it's important to note that Stock Contender provides guidance for stock identification, not investment advice, leaving the final decision-making to the user."
                url="https://github.com/TheTallProgrammer/Stock-Contender-App"
            />
            <ProjectCard 
                thumbnail="/assets/videos/react-cli-tool.mp4"
                title="React CLI Tool"
                description="The React Project CLI Tool is an indispensable asset for any React developer, dramatically accelerating project initiation by eliminating the drudgery of manual setup and code pruning. With its streamlined, pre-configured environment and integrated Git setup, it transforms project kick-off into a swift, seamless experience, focusing solely on essential development elements. This tool, crafted with a developer's insight, not only enhances productivity but also epitomizes a new standard in React project setup, making it a vital tool in every React developer's arsenal."
                url="https://github.com/TheTallProgrammer/react-cli-tool"
            />
        </div>
    );
};

export default Projects;
