import '../styles/HeaderParallaxBackground.scss';

const HeaderParallaxBackground = ({ offsetY }) => {
  return (
    <section className="Parallax">
      <div className="Parallax__background-logan" style={{ transform: `translate3d(0, ${offsetY * 0.2}px, 0)` }} />
      <div className="Parallax__background-foreground" style={{ transform: `translate3d(0, ${offsetY * 0.4}px, 0)` }} />
      <div className="Parallax__background-background" style={{ transform: `translate3d(0, ${offsetY * 0.6}px, 0)` }}/>
    </section>
  );
};
 
export default HeaderParallaxBackground;