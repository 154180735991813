import React from 'react';
import '../styles/Footer.scss';

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-links">
                    <a href="https://www.linkedin.com/in/logan-falkenberg" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    <span>|</span> {/* Separator */}
                    <a href="https://github.com/TheTallProgrammer" target="_blank" rel="noopener noreferrer">GitHub</a>
                </div>
                <div className="footer-made-with">
                    <span>Made with React</span>
                </div>
                <div className="footer-copy">
                    <span>&copy; {new Date().getFullYear()} Logan Falkenberg</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
