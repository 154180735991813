import Typewriter from 'react-typewriter-effect';


const TypeWriterEffect = ({ text, startDelayAmount, cursorBoolean, fontSize }) => {
    return (
        <div className="typewriter-effect-container" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%', // Take full width of the parent
            userSelect: 'none',
            margin: '10px 0', // Add some vertical spacing
        }}>
            <Typewriter
                textStyle={{
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: fontSize,
                    color: 'black'
                }}
                startDelay={startDelayAmount}
                cursorColor="black"
                text={text}
                typeSpeed={60}
                hideCursorAfterText={cursorBoolean} // Hide cursor after text if cursorBoolean is true
            />
        </div>
    );
};

export default TypeWriterEffect;