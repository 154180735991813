import Navbar from "./components/Navbar";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Resume, Contact, Home, Projects } from "./pages/index";
import Headroom from 'react-headroom';
import React, { useRef, useState, useEffect } from 'react';
import ScrollContext from "./scripts/ScrollContext";
import Footer from "./components/Footer";
import './styles/App.scss'
import ScrollToTop from "./scripts/ScrollToTop";
import { logAnalyticsEvent } from './scripts/Firebase.js'; // Import the function

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const aboutRef = useRef(null);
  const [shouldScrollToAbout, setShouldScrollToAbout] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      // Log a page_view event with the path name
      logAnalyticsEvent('page_view', { page_path: location.pathname });
    };
  
    // Call the route change handler on mount and every time the location changes
    handleRouteChange();
    
    // Since React Router v6 doesn't provide a direct listen method on navigate,
    // we need to use the useEffect hook to trigger the event logging
  }, [location]); // Only re-run the effect if the location changes
  

  const scrollToAbout = () => {
    // If we're already on the home page and the ref exists, scroll to it
    if (location.pathname === '/' && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
      setShouldScrollToAbout(false); // Reset the flag
    } else {
      // Otherwise, navigate to the home page and set a flag to scroll on arrival
      navigate("/");
      setShouldScrollToAbout(true);
    }
  };

  useEffect(() => {
    // This useEffect will run when the pathname changes or shouldScrollToAbout changes
    const handleScroll = () => {
      // If the flag is set and we're on the home page, scroll to the ref
      if (shouldScrollToAbout && location.pathname === '/') {
        scrollToAbout();
      }
    };

    // Call the scroll handler right after the component mounts in case we're already on the home page
    handleScroll();

    // Set up the scroll handler for future updates
    window.addEventListener('popstate', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleScroll);
    };
  }, [location.pathname, shouldScrollToAbout]); // Only re-run the effect if these values change

  return (
    <div className="App">
      <ScrollContext.Provider value={{ scrollToAbout }}>
        <Headroom style={{ transition: 'transform 300ms ease-in-out' }}>
          <Navbar />
        </Headroom>
        <Routes>
          <Route path="/" element={<Home aboutRef={aboutRef} />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/resume" element={<Resume />} />
        </Routes>
        <ScrollToTop/>
      </ScrollContext.Provider>
      <Footer>
          <h2>Footer</h2>
        </Footer>
    </div>
  );
}

export default App;
