import '../styles/Resume.scss'; // Ensure this is the correct path
import React, { useEffect, useRef } from 'react';

const Resume = () => {


  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.1 // Adjust if needed
      }
    );

    sectionRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const addToRefs = (el) => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el);
    }
  };
  
  return (
    <div className='resume-container'>
      <div className="resume-html">
        <header className="resume-header">
          <h1>Logan Falkenberg</h1>
          <div className="contact-info">
            <a href="mailto:loganf0101@gmail.com">loganf0101@gmail.com</a> |  
            <a href="http://loganfalkenberg.com" target="_blank" rel="noopener noreferrer"> Website</a>
          </div>
          <div className="contact-info">
            <a href="https://github.com/TheTallProgrammer" target="_blank" rel="noopener noreferrer">Github</a> | 
            <a href="https://www.linkedin.com/in/logan-falkenberg" target="_blank" rel="noopener noreferrer"> LinkedIn</a>
          </div>
        </header>
        <section ref={addToRefs} className="resume-section">
          <h2>PROFILE</h2>
          <p>An ambitious Computer Science student. I've demonstrated the ability to design, develop, and implement
          complex projects. My burgeoning technical proficiency spans a variety of programming languages, and I am
          passionate about producing user-friendly software solutions. I'm eager to learn and adapt to new technologies
          and challenges. I've worked collaboratively in teams and have experience leading small project segments to
          successful completion. As I pursue my degree, I'm actively seeking opportunities to grow my skills and
          contribute to meaningful projects. I have a particular interest in roles involving full-stack development, as well as
          specialized roles in either front-end or back-end development.</p>
        </section>
        <section ref={addToRefs} className="resume-section work-experience">
          <h2>WORK EXPERIENCE</h2>
          
          <div className="work-experience-entry">
            <h3>Code Ninjas</h3> {/* Main Title */}
            <div className="position-titles"> {/* Container for position titles */}
              <div className="position-details">
                <span className="role">Lead Instructor</span>{/* Role */}
                <span className="location">Kyle, TX</span> {/* Location */}
                <span className="date-range">(March 2019 - March 2024)</span> {/* Date Range */}
              </div>
              <div className="position-details">
                <span className="role">Instructor</span> {/* Role */}
                <span className="location">Dripping Springs, TX</span> {/* Location */}
                <span className="date-range">(March 2024 - Present)</span> {/* Date Range */}
              </div>
            </div>
            {/* List of responsibilities */}
            <ul className="work-experience-description">
            <li>Guided 1000+ students aged 7-14 from novice to advanced proficiency in Javascript, Lua, and C#, imparting crucial skills
                in syntax decoding, problem resolution, analytical thinking, and teamwork</li>
            <li>Developed and implemented educational materials and courses on programming languages such as C#, Lua, JavaScript, and Scratch, focusing on their complexities.</li>
            <li>Led a diverse team of 10+ members, driving consensus on coding practices and steering the inception, execution, and
          maintenance of numerous projects.</li>
            </ul>
          </div>
          
          {/* Repeat for other work experiences */}
        </section>


        <section ref={addToRefs} className="resume-section education">
        <h2>EDUCATION</h2>
        <div className="education-entry">
          <h3>Texas State University</h3>
          <div className="education-subtitle">
            <span className="education-subtitle-location">San Marcos, TX</span>
            <span className="education-subtitle-major">Bachelor of Science in Computer Science</span>
            <span className="education-subtitle-date">January 2023 - May 2025</span>
            </div>
          <li>Major in Computer Science; Minor in Mathematics</li>
          <li>Department GPA: 3.83/4.0, Additional BS - Computer Science Courses GPA: 3.88/4.0</li>
          <li>Achievements: Dean’s List (Spring 2023, Spring 2024)</li>
        </div>
        <div className="education-entry">
          <h3>Austin Community College</h3>
          <div className="education-subtitle">
            <span className="education-subtitle-location">Kyle, TX</span>
            <span className="education-subtitle-major">Associate of Science in Computer Science</span>
            <span className="education-subtitle-date">August 2020 - December 2022</span>
          </div>
          <li>Completed Associate Degree in Computer Science</li>
          <li>Cumulative GPA: 3.80/4.0</li>
          <li>Achievements: Scholastic Leadership Award (2 Consecutive Semesters)</li>
        </div>
      </section>
        <section ref={addToRefs} className="resume-section">
          <h2>RELEVANT SKILLS</h2>
          <h3>Technical Skills</h3>
          <ul>
            <li>Proficient in JavaScript, C++, Python, and Qt; familiar with HTML, CSS, SCSS, JSX, Java, Lua, and C#.</li>
            <li>Experienced in harnessing OpenAI’s API for impactful AI-driven applications, developing web solutions with React and Firebase, and employing PyQt5 in software project development.</li>
            <li>Extensive knowledge of GitHub; basic proficiency in SQL, Bitbucket, GitKraken, Jira, and Linux operating systems.</li>
          </ul>
        </section>
        <footer className="resume-footer">
        {/* I changed it to resume.pdf */}
          <a href="/assets/files/resume/resume.pdf" download="Logan_Falkenberg_Resume.pdf">Download Resume as PDF</a>
        </footer>
      </div>
    </div>
  );
};

export default Resume;
