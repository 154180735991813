import React, {useEffect, useState} from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { database } from '../scripts/Firebase.js'; // Adjust the import path as needed
import { ref, onValue, off } from 'firebase/database';
import '../styles/Contact.scss';

function ContactForm() {

  // have to give string ie "formID" initially so that the useForm has something to go off of or else it won't work.
  const [authToken, setAuthToken] = useState("formID");
  useEffect(() => {
      // Create a reference to the api node in the database
      const authTokenRef = ref(database, 'formID');

      // Listen for value changes at the reference
      onValue(authTokenRef, (snapshot) => {
          const data = snapshot.val();
          if (typeof data === 'string') {
              setAuthToken(data); // Update authToken in state
          }
      });

      // Cleanup listener when the component unmounts
      return () => off(authTokenRef);
  }, []);
  const [state, handleSubmit] = useForm(authToken); // Replace with your Formspree form ID
  
  if (state.succeeded) {
    return (
      <div className="thank-you-container">
        <h2>Thank You!</h2>
        <p>Your message has been sent successfully. I'll get back to you as soon as I can.</p>
      </div>
    );
  }
  
  return (
    <div className="contact-container">
      <h2>Contact Me</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" required />
        <ValidationError prefix="Name" field="name" errors={state.errors} />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required />
        <ValidationError prefix="Email" field="email" errors={state.errors} />

        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" rows="6" required></textarea>
        <ValidationError prefix="Message" field="message" errors={state.errors} />

        <button type="submit" disabled={state.submitting}>
          Send
        </button>
      </form>
      <div className='white-space-filler'/>
    </div>
  );
}

export default ContactForm;
