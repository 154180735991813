import React from 'react';
import '../styles/ProjectCard.css';

const ProjectCard = ({ thumbnail, title, description, url }) => {
    return (
        <div className="project-card">
            {/* Replace img tag with video tag */}
            <video 
                src={thumbnail} 
                alt={title} 
                className="project-thumbnail" 
                loop 
                autoPlay 
                muted 
                playsInline
            >
                {/* Fallback for browsers that don't support video */}
                <source src={thumbnail} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="project-info">
                <h3>{title}</h3>
                <p>{description}</p>
                <a href={url} target="_blank" rel="noopener noreferrer">View Repo</a>
            </div>
        </div>
    );
};

export default ProjectCard;
