import React, {useState, useEffect} from 'react';
import TypeWriterEffect from './TypeWriterEffect';
import '../styles/TypeWriterWrapper.scss'

const TypeWriterWrapper = () => {
    const [wrapperStyle, setWrapperStyle] = useState({
        height: '82px',
        width: '85%',
        fontSize: '1rem'
    });

    // New state for controlling the opacity of the second typewriter
    const [secondTypewriterOpacity, setSecondTypewriterOpacity] = useState(0);

    // Update the opacity of the second typewriter after 2100ms
    useEffect(() => {
        const timer = setTimeout(() => {
            setSecondTypewriterOpacity(1);
        }, 2100);

        return () => clearTimeout(timer);
    }, []);

    const updateStyle = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 384) {
            setWrapperStyle({ height: '82px', width: '100%', fontSize: '1rem' });
        }
        else if (screenWidth < 401) {
            setWrapperStyle({ height: '82px', width: '100%', fontSize: '1.1rem' });
        }
        else if (screenWidth < 507) {
            setWrapperStyle({ height: '82px', width: '100%', fontSize: '1.3rem' });
        }
        else if (screenWidth < 605) {
            setWrapperStyle({ height: '82px', width: '100%', fontSize: '1.4rem' });
        }
        else if (screenWidth < 768) {
            setWrapperStyle({ height: '82px', width: '100%', fontSize: '1.5rem' });
        } else if (screenWidth < 992) {
            setWrapperStyle({ height: '100px', width: '100%', fontSize: '1.6rem' });
        } else if (screenWidth >= 992 && screenWidth <= 1120) {
            setWrapperStyle({ height: '120px', width: '100%', fontSize: '1.6rem' });
        } else {
            // For screens larger than 1120px, the style remains static
            setWrapperStyle({ height: '120px', width: '100%', fontSize: '1.7rem' });
        }
    };

    useEffect(() => {
        updateStyle();
        window.addEventListener('resize', updateStyle);
        return () => {
            window.removeEventListener('resize', updateStyle);
        };
    }, []);

    return (
        <div className="typewritter-wrapper" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: wrapperStyle.height,
            width: wrapperStyle.width,
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // backdropFilter: 'blur(5px)',
            // backgroundColor: 'rgba(255, 255, 255, 0.7)',
            // borderRadius: '5px',
            padding: '20px',
            // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: -1,
            animation: 'fadeIn 1.5s ease forwards',
            fontSize: wrapperStyle.fontSize // Set font size for children
        }}>
            <div>
                <TypeWriterEffect
                    text="Hi, I'm Logan."
                    startDelayAmount={350}
                    cursorBoolean={true}
                    fontSize={wrapperStyle.fontSize}
                />
            </div>
            <div style={{ opacity: secondTypewriterOpacity }}>
                <TypeWriterEffect
                    text="Welcome to my portfolio."
                    startDelayAmount={2050}
                    cursorBoolean={true}
                    fontSize={wrapperStyle.fontSize}
                />
            </div>
        </div>
    );
};

export default TypeWriterWrapper;