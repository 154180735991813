import '../styles/Navbar.scss';
import React, { useState, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import ScrollContext from '../scripts/ScrollContext';


const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const { scrollToAbout } = useContext(ScrollContext);

    const handleHomeClick = () => {
        setToggleMenu(false);
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const handleAboutClick = () => {
        scrollToAbout();
        setToggleMenu(false);
    };

    const handleNavLinkClick = (e) => {
        // When a nav link is clicked, close the menu
        if(e.target.id === 'about-link') {
            scrollToAbout();
        }
        setToggleMenu(false);
    };

    const handleCheckboxChange = () => {
        // Toggle the menu when the checkbox changes
        setToggleMenu(!toggleMenu);
    };

    return (
        <nav className="navbar">
            <Link to="/" className="title" onClick={handleHomeClick}>Home</Link>
            <div className="menu">
                <label className="hamburger-menu">
                    <input 
                        type="checkbox" 
                        checked={toggleMenu} 
                        onChange={handleCheckboxChange} 
                    />
                </label>
            </div>
            <ul className={toggleMenu ? "menuActive" : ""}>
                <li>
                    <a id="about-link" onClick={handleAboutClick} style={{cursor: 'pointer'}}>About Me</a>
                </li>
                <li>
                    <NavLink to="/projects" onClick={handleNavLinkClick}>Projects</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" onClick={handleNavLinkClick}>Contact</NavLink>
                </li>
                <li>
                <NavLink to="/resume" onClick={handleNavLinkClick}>Resume</NavLink>
                </li>
            </ul>
        </nav>
    );
}
 
export default Navbar;
